<template>
  <page-view :title="title">
    <div class="user_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" class="filter_input" v-model.trim="queryParam.uid" />
          </div>
        </a-col>

        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>登录名</span>
            <a-input placeholder="登录名" class="filter_input" v-model.trim="queryParam.username" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>手机号</span>
            <a-input placeholder="手机号" class="filter_input" v-model.trim="queryParam.mobile" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0', marginRight: '19px' }">
          <div class="filter_item">
            <span>支付id</span>
            <a-input placeholder="支付id" class="filter_input" v-model.trim="queryParam.payAccount" />
          </div>
        </a-col>
        <a-col :lg="3" :md="12" :sm="24" :style="{ paddingLeft: '0', paddingRight: '0' }">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="resetQuery">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="user_table_wrapper">
      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="logo" slot-scope="text">
          <img :src="text" style="width: 45px;height: 45px;" />
        </template>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @mouseover="handleMouseover(record)" @mouseout="handleMouseout(record)" @click="copyInfo(record.uid)">
              {{ text.substring(0, 4) + '……' + text.substring(text.split('').length - 4) }}
              <span class="uid_child" v-show="record.showid">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>

        <template slot="operation" slot-scope="text, record">
          <a @click="update(record)">修改</a>
          <a style="margin-left: 8px;" @click="showBillList(record)">查看资金变动</a>
          <a style="margin-left: 8px;" @click="refund(record.uid)">退款</a>
        </template>
      </s-table>
    </div>

    <a-modal title="修改" :width="560" :visible="edit.show" v-if="edit.show" :maskClosable="false" @cancel="handleCancel" @ok="handleOk">
      <div class="input_group">
        <span>登录名：</span>
        <a-input class="top5" placeholder="登录名" v-model="edit.username" />
      </div>
      <div class="input_group">
        <span>手机号：</span>
        <a-input class="top5" placeholder="手机号" v-model="edit.mobile" />
      </div>
      <div class="input_group">
        <span>封号：</span>
        <a-switch :checked="edit.blocked" @change="chanegEditBlocked" />
      </div>
      <div class="input_group">
        <span>邮箱地址：</span>
        <a-input class="top5" placeholder="邮箱" v-model="edit.email" />
      </div>
      <div class="input_group">
        <span>用户角色：</span>
        <a-select style="width:320px;height:32px" mode="multiple" v-model="edit.roles" placeholder="请选择用户角色">
          <a-select-option v-for="item in rolesList" :key="item.value" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
      </div>
    </a-modal>

    <a-modal :width="560" v-model="refundShow" title="退款申请" @ok="handleRefundOk" @cancel="closeRefund">
      <div class="input_group">
        <span>用户ID：</span>
        <a-input class="top5" placeholder="请输入用户id" v-model.trim="refundData.uid" />
      </div>
      <div class="input_group">
        <span>备注：</span>
        <a-input class="top5" placeholder="请输入备注" v-model.trim="refundData.comment" />
      </div>
      <div class="input_group">
        <span>退款金额：</span>
        <a-input class="top5" placeholder="请输入退款金额" v-model.trim="refundData.amount" />
      </div>
    </a-modal>
    <a-modal
      :width="1080"
      v-model="billListVisible"
      title="资金变动详情"
      @ok="closeBillList"
      @cancel="closeBillList"
      :bodyStyle="{
        overflow: 'auto'
      }"
    >
      <a-table ref="table2" rowKey="id" :columns="billColumns" :data-source="billListData" :pagination="false">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <!-- 流水 -->
        <template slot="no" slot-scope="text, record">
          {{ text }}
        </template>
      </a-table>
      <a-pagination
        class="pagin"
        v-if="billListData && billListData.length > 0"
        :current="billPage.pageNum"
        @change="billPageChange"
        :defaultCurrent="1"
        :defaultPageSize="billPage.pageSize"
        :total="billPage.total"
      />
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from '@/components';
import { PageView } from '@/layouts';
import { sipUserList, sipRoleUpdateUser, sipUpdate, accountRefundBalance, accountList, billAdminList, sipUserUpdate, sipUserByUid } from '@api';
import { formatDate, roleCodeToName, billType } from '@/utils/util';

const rolesCustomRender = (value, row, index) => {
  let roleNames = '';
  value.map((it) => {
    roleNames += roleCodeToName[it].name + ';';
  });
  return roleNames;
};

export default {
  data() {
    return {
      billQueryId: '',
      billListVisible: false,
      billListData: [],
      billPage: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      refundData: {
        uid: '',
        amount: '',
        comment: ''
      },
      refundShow: false,
      rolesList: [],

      edit: { show: false },
      queryParam: {},
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '支付id',
          dataIndex: 'payAccount'
        },
        {
          title: '登录名',
          dataIndex: 'username'
        },
        {
          title: '手机号',
          dataIndex: 'mobile'
        },
        {
          title: '账户余额',
          dataIndex: 'balance',
          sorter: (a, b) => a.balance - b.balance
        },
        {
          title: '可提现余额',
          dataIndex: 'balanceWithdrawabl',
          sorter: (a, b) => a.balanceWithdrawabl - b.balanceWithdrawabl
        },
        {
          title: '提现中余额',
          dataIndex: 'balanceCash',
          sorter: (a, b) => a.balanceCash - b.balanceCash
        },
        {
          title: '总提现金额',
          dataIndex: 'balanceCashAll',
          sorter: (a, b) => a.balanceCashAll - b.balanceCashAll
        },

        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate,
          sorter: (a, b) => a.createTime - b.createTime
        },
        {
          title: '最后登录时间',
          dataIndex: 'lastLoginTime',
          customRender: formatDate
        },
        {
          title: '最后付费时间',
          dataIndex: 'lastPayTime',
          customRender: formatDate
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      billColumns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          width: 60
        },

        {
          title: '流水号',
          dataIndex: 'no',
          scopedSlots: { customRender: 'no' }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          customRender: (text) => text + '元',
          width: 100
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: (text) => billType[text],
          width: 80
        },
        {
          title: '原账户余额',
          dataIndex: 'oldBalance',
          width: 130
        },
        {
          title: '现账户余额',
          dataIndex: 'newBalance',
          width: 130
        },
        {
          title: '原可提现余额',
          dataIndex: 'oldBalanceWithdrawabl',
          width: 140
        },
        {
          title: '现可提现余额',
          dataIndex: 'newBalanceWithdrawabl',
          width: 140
        },
        {
          title: '原总提现金额',
          dataIndex: 'oldBalanceCashAll',
          width: 140
        },
        {
          title: '原总提现金额',
          dataIndex: 'newBalanceCashAll',
          width: 140
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate
        }
      ],
      dataSource: (parameter) => {
        return accountList('admin', Object.assign(parameter, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => (item.showid = false));
          return result.data;
        });
      }
    };
  },
  created() {
    //修改角色列表
    const array = [];
    for (let key in roleCodeToName) {
      array.push({ name: roleCodeToName[key].name, value: key });
    }
    this.rolesList = array;
  },
  components: {
    PageView,
    STable
  },
  methods: {
    //资金变动
    showBillList(record) {
      this.billListVisible = true;
      this.billListData = []; //每次打开弹框清空数据
      this.billPage.pageNum = 1;
      this.billQueryId = record.uid;
      this.getBillList();
    },
    getBillList() {
      const { billPage, billQueryId } = this;
      let params = {
        uid: billQueryId,
        pageNum: billPage.pageNum,
        pageSize: billPage.pageSize
      };
      billAdminList(params).then((res) => {
        if (res.success) {
          this.billListData = res.data.list;
          this.billPage = res.data.page;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    billPageChange(page) {
      this.billPage.pageNum = page;
      this.getBillList();
    },
    closeBillList() {
      this.billListVisible = false;
    },
    handleMouseover(record) {
      record.showid = true;
    },
    handleMouseout(record) {
      record.showid = false;
    },
    closeRefund() {
      this.refundData = {};
      this.refundShow = false;
    },
    refund(uid) {
      this.refundData.uid = uid;
      this.refundShow = true;
    },
    chanegEditBlocked() {
      this.edit.blocked = !this.edit.blocked;
    },
    handleRefundOk() {
      if (!this.refundData.amount) {
        return this.$message.error('退款金额必填');
      }
      accountRefundBalance(this.refundData).then((res) => {
        if (res.success) {
          this.$message.success('退款成功');
          this.refundData = {};
          this.refundShow = false;
        } else {
          this.$message.error('退款失败');
        }
      });
    },
    // 重置
    resetQuery() {
      this.queryParam = {};
      this.$refs.table.refresh(false);
    },

    update(record) {
      //record.show = true;
      // this.edit = JSON.parse(JSON.stringify(record));
      sipUserByUid(record.uid).then((res) => {
        if (res) {
          res.show = true;
          this.edit = JSON.parse(JSON.stringify(res));
          this.edit.roles = this.edit.roles.filter((item) => {
            return item !== 'NORMAL';
          });
        } else {
          this.$message.error('出错了');
        }
      });
    },

    handleCancel() {
      this.edit = { show: false };
    },
    handleOk() {
      const edit = this.edit;
      //修改前三个
      sipUserUpdate({
        id: edit.id,
        username: edit.username,
        mobile: edit.mobile,
        email: edit.email,
        blocked: edit.blocked
      }).then((res) => {
        if (res.success) {
          // 填了role才修改角色
          if (edit.roles) {
            const roleIds = [];
            edit.roles.map((it) => {
              roleIds.push(roleCodeToName[it].id);
            });
            sipRoleUpdateUser({ userIds: [edit.id], roleIds: roleIds }).then((res) => {
              if (res.success) {
                this.$message.success('成功');
                this.$refs.table.refresh(false);
                this.edit = { show: false };
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            //没有role直接提示
            this.$message.success('成功');
            this.$refs.table.refresh(false);
            this.edit = { show: false };
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.user_header_wrapper {
  padding: 24px 56px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-right: 12px;
    }
  }
}
.user_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: PingFang SC;
  padding: 24px;
}

.input_group {
  display: flex;
  margin-bottom: 24px;
  // align-items: center;
  span {
    width: 88px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
}
.top5 {
  width: 320px;
  height: 32px;
}
.pagin {
  margin-top: 24px;
  margin-right: 24px;
  text-align: right;
}
</style>
